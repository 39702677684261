exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-beauty-index-js": () => import("./../../../src/pages/beauty/index.js" /* webpackChunkName: "component---src-pages-beauty-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-culture-index-js": () => import("./../../../src/pages/culture/index.js" /* webpackChunkName: "component---src-pages-culture-index-js" */),
  "component---src-pages-fashion-index-js": () => import("./../../../src/pages/fashion/index.js" /* webpackChunkName: "component---src-pages-fashion-index-js" */),
  "component---src-pages-food-index-js": () => import("./../../../src/pages/food/index.js" /* webpackChunkName: "component---src-pages-food-index-js" */),
  "component---src-pages-gaming-index-js": () => import("./../../../src/pages/gaming/index.js" /* webpackChunkName: "component---src-pages-gaming-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-tech-index-js": () => import("./../../../src/pages/tech/index.js" /* webpackChunkName: "component---src-pages-tech-index-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tvandmovies-index-js": () => import("./../../../src/pages/tvandmovies/index.js" /* webpackChunkName: "component---src-pages-tvandmovies-index-js" */),
  "component---src-pages-updates-index-js": () => import("./../../../src/pages/updates/index.js" /* webpackChunkName: "component---src-pages-updates-index-js" */),
  "component---src-templates-posttemp-js": () => import("./../../../src/templates/posttemp.js" /* webpackChunkName: "component---src-templates-posttemp-js" */),
  "component---src-templates-producttemp-js": () => import("./../../../src/templates/producttemp.js" /* webpackChunkName: "component---src-templates-producttemp-js" */),
  "component---src-templates-usertemp-js": () => import("./../../../src/templates/usertemp.js" /* webpackChunkName: "component---src-templates-usertemp-js" */)
}

